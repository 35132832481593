.map {
  /* padding: 20px; */
}

.mapContainer {
  border: 1.5px solid #00ffc3;
  background-color: #000000;
  border-radius: 20px;
}
.hoverTooltip{
  position: absolute;
  background-color: #000000;
  border: 1px solid #00ffc3;
  color: #ffffff;
  padding: 20px;
  font-size: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mapResponsive {
  width: 100%;
  height: 500px;
  border-radius: 20px;
}
.mapResponsive div {
  border-radius: 20px !important;
}
.makerSection{
  position: relative !important;
}

/* .hoverTooltip {
  position: relative;
  background: white;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
} */

.closeIcon {
  position: absolute;
  top: 6px;
  right: 7px;
  width: 16px; 
  height: 16px; 
  cursor: pointer;
}

.closeIcon:hover {
  opacity: 0.8; /* Add hover effect */
}


@media (max-width: 1400px) {
  .mapResponsive {
    height: 400px;
  }
}

@media (max-width: 992px) {
  .mapResponsive {
    height: 300px;
  }
}
