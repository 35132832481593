/* Style the brand history Section */
.brandHistorySection {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.errorContainer{
  padding: 20px;
  background-color: #000;
  color: #00ffc3;
  margin: 20px;
  border-radius: 20px;
  font-size: 18px;
  width: 95% !important;
}
.brandHistoryButton {
    border: 1px solid #00ffc3 !important;
    align-items: center;
    background-color: #0fc;
    border: none;
    border-radius: 10px;
    color: #000;
    column-gap: 8px;
    cursor: pointer;
    display: flex;
    padding: 10px 20px;
}

.brandHistoryButton:hover {
    background-color: #000000;
    color: #ffffff;
}

.brandHistoryButton span {
    font-weight: 600;
    font-size: 15px;
}

.brandImg {
    height: auto;
    width: 24px;
}

.brandHistoryButton:hover .brandImg {
    filter: brightness(0) invert(1);
}

.detailsListSection {
    background-color: #242424;
}

.table>thead {
    vertical-align: top !important;
}

.addressListContainer {
    display: flex;
    background-color: #242424;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    row-gap: 20px;
    justify-content: start;
    align-items: start;
}

.secondTitle {
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
}

.sectionTitle {
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
}

.customTable {
    /* background-color: #000 !important; */
    border-radius: 14px !important;
    overflow: hidden;
}
.brandImage{
    cursor: pointer;
}
.customTable thead {
    background-color: #000000;
    border-bottom: #000 !important;
}

.customTable th {
    color: #00ffc3 !important;
    background-color: #000000 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    padding: 16px !important;
    text-align: left;
}
.customTable th:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.customTable th:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}
.customTable td {
    color: #ffffff !important;
    background-color: #242424 !important;
    padding: 12px 16px !important;
    font-size: 13px !important;
    vertical-align: middle;
}
.brandImage {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
}
.customTable {
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
}

.editContent {
    display: flex;
    column-gap: 10px;
    cursor: pointer;
}

.editContent img {
    width: 26px;
}
/* Start the Modal Icon */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align modal at the top */
    z-index: 1000;
}

.modalContent {
    background:#000000;
    border: 1px solid #00ffc3;
    padding: 20px;
    border-radius: 5px;
    width: 650px;
    max-width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 80px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.modalHeading{
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
}
.closeButton {
    color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.buttonGroup {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 20px;
}

.assignButton {
    background-color: #00ffc3;
    color: #000000;
    border: none;
    padding: 8px 22px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
}

.assignButton:hover,
.closeModalButton:hover {
    background-color: #000;
    border: 1px solid #00ffc3;
    color: #ffffff;
}

.closeModalButton {
    background-color: #ffffff;
    color: #000;
    border: none;
    padding: 8px 22px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
}
.inputField{
    background-color: #242424;
    border: none;
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    outline: none;
    padding: 16px 10px;
    width: 100%;
}
/* Style the PODZONE modal class */
.modalContainer{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.mapResponsive{
    height: 300px !important;
    width: 100%;
    border-radius: 14px;
    border: 2px solid #00ffc3;
}
.truncatedText {
    display: inline-block;
    max-width: 400px; /* Adjust width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

/* Start the sttaus toogle button container */
.bookingStatusHead{
    font-size: 28px;
    font-weight: 600;
    color: #00ffc3;
}
/* .bookingStatusContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: Arial, sans-serif;
  }
  .radioSection{
    border: 1px solid #00ffc3;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .statusOptions {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
  }
  
  .statusOption {
    background-color: #000000;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    padding: 14px;
    border: 1px solid #00ffc3;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  .statusOption span.radio {
    width: 16px;
    height: 16px;
    border: 2px solid #aaa;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }
  
  .statusOption.active span.radio {
    border-color: #00ffc3; 
    background-color:#00ffc3;
  }
  
  .statusOption span {
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
  }
  
  .statusOption.active {
    color: #00ffc3;
  } */
.bookingStatusHead {
    font-size: 24px;
    font-weight: 550;
    margin-bottom: 10px;
}

.statusOptions {
    display: flex;
    gap: 10px;
}

.statusOption {
    gap: 10px;
    background-color: #000000;
    display: flex;
    align-items: center;
    padding: 10px;
    border: 2px solid #4E4E4E; /* Default border */
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.statusOption.active {
    border-color: #00ffc3; /* Green border when active */
}
.statusHead{
    font-size: 16px;
    font-weight: 500;
    color: #4e4e4e;
}

.radioSection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 2px solid #4E4E4E;
    border-radius: 6px;
    transition: all 0.3s ease;
}

/* When the section and radio are active */
.radioSectionActive {
    border-color: #00ffc3; /* Green border for radio section */
}
.statusHeadActive{
    color: #ffffff;
}

.radio {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #4e4e4e;
    transition: all 0.3s ease;
}

.radioActive {
    background-color: #00ffc3; /* Fill radio button when active */
}


  
