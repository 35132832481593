.containerCharger {
  background-color: #242424;
  height: 94vh;
  overflow: scroll;
  padding: 20px;
  color: #ffffff;
}

.containerCharger::-webkit-scrollbar {
  display: none;
}

.slotMainFormSection {
  display: flex;
      align-items: center;
      gap: 14px;
      padding: 0px 10px;
}

.slotHeaderSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datePickerWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

.datePickerImg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 30px;
  pointer-events: none;
}

.title {
  color: #00ffc3;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.chargerSection {
  padding: 30px;
  border-radius: 14px;
  border: 1px solid #00ffc3;
  background-color: #000000;
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  margin-top: 20px;
}

.row {
  display: flex;
  column-gap: 20px;
  margin-bottom: 10px;
}

.inputGroup {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.inputGroup:last-child {
  margin-right: 0;
}

.label {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
}

.inputCharger {
  outline: none;
  background-color: #4e4e4e;
  border: none;
  padding: 16px 10px;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  width: 100%;
}

.select {
  appearance: none;
  outline: none;
  background-color: #242424;
  border: none;
  padding: 16px 10px;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.iconContainer {
  position: fixed;
  right: 62px;
  top: 22%;
  font-size: 16px;
  color: #ffffff;
  z-index: 2;
}

.select option {
  cursor: pointer !important;
}

.actions {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin-top: 20px;
}

.cancelBtn {
  background-color: #ffffff;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
}

.timePickers {
  outline: none !important;
}

.submitBtn {
  border: 1px solid #00ffc3;
  background-color: #00ffc3;
  color: #000000;
  outline: none;
  border: none;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
}

.submitBtn:hover {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #00ffc3;
}

.cancelBtn:hover {
  border: 1px solid #00ffc3;
  background-color: #000000;
  color: #ffffff;
}

.fileUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

.fileLabel {
  width: 100%;
  font-size: 18px;
  margin-bottom: 10px;
  color: #ffffff;
}

.fileDropZone {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 14px;
  background-color: #242424;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.fileUploadLabel {
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  column-gap: 22px;
  cursor: pointer;
}

.uploadIcon {
  width: 80px;
  height: 80px;
}

.imageContainer {
  position: absolute;
  display: inline-block;
}

.previewImage {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.removeButton {
  position: absolute;
  background-color: #00ffc3;
  color: #000000 !important;
  padding: 0px;
  top: 5px;
  right: 5px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 4px;
}

.addSection {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  gap: 20px;
}

.buttonSec {
  border: 1px solid #00ffc3 !important;
  background-color: #00ffc3;
  color: #000000;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-radius: 6px;
  gap: 10px;
  padding: 6px 10px;
}

.buttonSec:hover {
  background-color: #000000;
  color: #ffffff;

}

.addImg {
  width: 14px;
}

.buttonSec:hover .addImg {
  filter: brightness(0) invert(1);
}

.addContent {
  font-size: 16px;
}

.buttonContainer {
  background-color: #242424 !important;
}
.removeContent{
  width: 34px;
  position: relative;
  top: 14px;
}
/* Container for toggle switch */
.toggleContainer {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: start;
}

/* Label for status */
.statusLabel {
  color: #ffffff;
  font-size: 16px;
}

/* Toggle switch area */
.toggleSwitch {
  padding: 13px 0px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  background-color: #242424;
}

/* Toggle button container */
.toggleButton {
  position: relative;
  border: 2px solid #828282; /* Default gray border */
  background-color: #242424;
  border-radius: 6px;
  width: 50px;
  height: 30px; 
  transition: all 0.3s ease;
}

/* Active state background and slider movement */
.toggleButton.active {
  border: 2px solid #00ffc3; /* Green border when active */
}

.toggleButton.inactive {
  border: 2px solid #828282; /* Gray border when inactive */
}

/* Slider inside the button */
.slider {
  position: absolute;
  top: 50%;
  left: 0px;
  width: 24px;
  height: 30px;
  background-color: #828282; /* Default gray slider */
  border-radius: 4px;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}

/* When active, move the slider to the right and change color */
.toggleButton.active .slider {
  left: 22px;
  background-color: #00ffc3; /* Green for slider when active */
}

/* Text for Active and Inactive */
.toggleText {
width: 50px;
  font-size: 12px;
  color: #828282; /* Default gray text color */
  transition: color 0.3s ease;
}

/* Active text color */
.toggleText.activeText {
  color: #00ffc3; /* Green for active text */
}

/* Inactive text color */
.toggleText.inactiveText {
  color: #828282; /* Gray for inactive text */
}
 

/* End the feature section */

/* Start the styling in table section */

.TimeslotcontainerCharger {
  padding: 20px;
  background-color: #242424;
  color: white;
  font-family: "Inter", sans-serif !important;
}

.editContent {
  display: flex;
  column-gap: 10px;
  cursor: pointer;
}

.editContent img {
  width: 24px;
  cursor: pointer;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table thead {
  background-color: #000000;
  color: #00ffcc !important;
}

.table th {
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.table td {
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  /* border-bottom: 1px solid #ffffff; */
}

.table thead th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table thead th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.editButton,
.deleteButton {
  background-color: #2e2e2e;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 3px;
}

.timeSlotGroup {
  border-bottom: 1px solid #ffffff !important;
}

.listSpan {
  padding: 0px 14px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #00ffcc !important;
  border-bottom: none !important;
  position: relative;
  top: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.timeSlotContent {
  background-color: #000000;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 18px;
  padding: 10px;
  border-radius: 8px;
}

/* End the styling in table section */