.sidebar {
  position: relative;
  width: 260px;
  background-color: #000000;
  color: white;
  height: 100vh;
  overflow: scroll;
  padding: 20px;
  font-family: "Inter", sans-serif !important;
}
.sidebar::-webkit-scrollbar {
  display: none;
}
.hamburger {
  display: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.sidebarContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* transition: transform 0.3s ease-in-out; */
}

.sidebarContainer.hide {
  transform: translateX(-100%);
}

.sidebarContainer.show {
  transform: translateX(0);
}
.subMenu {
  text-decoration: none !important;
  padding-left: 0rem !important;
  list-style-type: none !important;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.subMenu li {
  display: flex;
  column-gap: 10px;
  justify-content: start;
  align-items: center;
  cursor: pointer;
}
.logo {
  display: flex;
  justify-content: center;
  padding: 22px 0px 10px;
}
/* Logo */
.logo img {
  display: block;
  max-width: 100%;
}

/* Menu list styles */
.menuList {
  list-style-type: none;
  padding: 0;
}

.menuItem {
  padding: 10px 0;
  font-size: 14px;
  border-bottom: 2px dashed #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s ease;
}

.menuItem:hover,
.menuItem:focus,
.menuItem.active {
  color: #00ffc3 !important;
}

.menuItemdropdown {
  cursor: pointer;
}

.menuItemdropdown.active {
  color: #00ffc3 !important;
}

.menuItemdropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.menuItemDiv {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  font-size: 14px;
  border-bottom: 2px dashed #ffffff;
}
.dropdownMenuItem:hover {
  color: #00ffc3 !important;
}
.activeItem {
  color: #00ffc3 !important;
}
.checkboxInput {
  margin-right: 10px;
}
.arrow {
  font-size: 12px;
  font-weight: 800;
}
.checkboxInput {
  display: none;
}

.checkmark {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #00ffc3;
  border-radius: 2px;
  cursor: pointer;
  background-color: #000000;
  transition: background-color 0.3s, border-color 0.3s;
}
a {
  color: #ffffff !important;
}
/* Style when checkbox is checked */
.checkboxInput:checked + .checkmark {
  background-color: #00ffc3;
  border-color: #00ffc3;
}

/* Checkmark */
.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

/* Show checkmark when checked */
.checkboxInput:checked + .checkmark::after {
  display: block;
}

@media (max-width: 992px) {
  .hamburger {
    display: block;
  }
  .sidebarContainer {
    position: absolute;
    padding: 20px;
    top: 37px;
    left: 0;
    background-color: #000;
    z-index: 1;
    width: 260px;
    transform: translateX(-100%);
  }
  .sidebarOpen {
    width: 260px;
  }

  .sidebarClosed {
    width: 100%;
    padding: 22px;
  }
  .logo {
    padding: 0px 0px 10px;
  }
}
