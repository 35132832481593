.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    cursor: pointer;
}

.pagination span {
    margin: 0 5px;
    cursor: pointer;
}

.icon{
    width: 8px;
}
.pagination li {
    display: inline;
    margin: 0 2px;
    padding: 0px 10px;
    border-radius: 4px;
}

.activePage {
    color: #00ffcc;
}
.activePage a {
    color: #00ffcc !important;
    font-size: 16px !important;
}