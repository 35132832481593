.containerCharger{
    background-color: #242424;
    height: 94vh;
    overflow: scroll;
    padding: 20px;
    color: #ffffff;
  }
  .containerCharger::-webkit-scrollbar{
    display: none;
  }
  .title {
    color: #00ffc3;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .chargerSection{
    padding: 30px;
    border-radius: 14px;
    border: 1px solid #00ffc3;
    background-color: #000000;
    display: flex;
    flex-direction: column;
  }
  
  .form {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
  }
  
  .row {
    display: flex;
    column-gap: 20px;
    margin-bottom: 10px;
  }
  
  .inputGroup {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  
  .inputGroup:last-child {
    margin-right: 0;
  }
  
  .label {
    font-size: 18px;
    color: #ffffff;
    font-weight: 500;
  }
  
.inputCharger{
    outline: none;
    background-color: #242424;
    border: none;
    padding: 16px 10px;
    border-radius: 6px;
    color: #ffffff;
    font-size: 16px;
    width: 100%;
}

  .select {
    appearance: none;
    outline: none;
    background-color: #242424;
    border: none;
    padding: 16px 10px;
    border-radius: 6px;
    color: #ffffff;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
  .iconContainer {
    position: fixed;
    right: 62px;
    top: 22%;
    font-size: 16px;
    color: #ffffff;
    z-index: 2;
}
  .select option{
    cursor:  pointer !important;
  }
  .actions {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    margin-top: 20px;
  }
  /* style the error section */
  .errorContainer{
    padding: 20px;
    background-color: #000;
    color: #00ffc3;
    margin: 20px;
    border-radius: 20px;
    font-size: 18px;
  }
  /* end te error section */
  
  .cancelBtn {
    background-color: #ffffff;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    outline: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 8%;
  }
  
  .submitBtn {
    background-color:#00ffc3;
    color: #000000;
    outline: none;
    border: none;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .submitBtn:hover {
    background-color:#00ffc3;
  }
  
  .cancelBtn:hover {
    background-color: #ffffff;
  }
  .fileUpload {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
}

.fileLabel {
    width: 100%;
    font-size: 18px;
    margin-bottom: 10px;
    color: #ffffff; 
}

.fileDropZone {
    position: relative;
    width: 100%;
    height: 250px;
    border-radius: 14px;
background-color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.fileUploadLabel {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    column-gap: 22px;
    cursor: pointer;
}

.uploadIcon {
    width: 80px;
    height: 80px;
}

.imageContainer {
    position: absolute;
    display: inline-block;
}

.previewImage {
  width: 200px;
  height: 200px;
    object-fit: cover;
}

.removeButton {
    position: absolute;
    background-color:#00ffc3;
    color: #000000 !important;
    padding: 0px;
    top: 5px;
    right: 5px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 4px;
}

/* Container for toggle switch */
.toggleContainer {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #242424;
  padding: 18px 10px;
  border-radius: 14px;
  border: 1px solid #00ffc3;
}

/* Label for status */
.statusLabel {
  color: #ffffff;
  font-size: 18px;
}

/* Toggle switch area */
.toggleSwitch {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
  padding: 5px;
}

/* Toggle labels for Un-active and Active */
.toggleLabel {
  color: #00ffc3;
  font-size: 12px;
  padding: 0 6px;
}

.toggleLabel.active {
  color: #00ffc3;
}

.toggleLabel.inactive {
  color: #828282;
}

/* Toggle button container */
.toggleButton {
  position: relative;
  border: 2px solid #00ffc3;
  background-color: #242424;
  border-radius: 6px;
  width: 60px;
  height: 28px;
  margin: 0 6px;
  transition: background-color 0.3s ease;
}

/* Active state background */
.toggleButton.active {
  border: 2px solid #00ffc3;
}

/* Slider inside the button */
.slider {
  position: absolute;
  top: 50%;
  left: 0px;
  width: 26px;
  height: 26px;
  background-color: #00ffc3;
  border-radius: 4px;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}

.toggleButton.active .slider {
  left: 30px;
}

/* End the feature section */
/* style the add buttons ection */
.batteryBattle{
  font-size: 24px;
  font-weight: 600;
  color: #00ffc3;
}
.mainAddSection{
  width: 100%;
  display: flex;
  justify-content: space-between;
   align-items: center;
}
.addButton {
  border: 1px solid #00ffc3 !important;
  background-color: #00ffc3;
  font-weight: 600;
  display: flex;
  color: #000000;
  padding: 6px 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.addButton:hover {
  background-color: #000000;
  color: #ffffff;
}
.addButton:hover .imageShopList {
  filter: brightness(0) invert(1);
}
.addButton span {
  font-size: 18px;
  font-weight: 600;
}
.buttonContainer{
  background-color: #000000;
  position: relative;
    top: 16px;
}
.removeContent{
  width: 34px;
  cursor: pointer;
}
/* End the button style */