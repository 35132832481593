body{
    font-family: "Inter", sans-serif !important;
}
.container-fluid{
    padding: 20px !important;
    background-color: #242424;
    color: white;
    font-family: "Inter", sans-serif !important;
  }
  .row{
    row-gap: 20px !important;
  }
  .table>thead {
    vertical-align: top !important  ;
  }
  /* Style the multi dropdown section */
.rmsc .dropdown-container {
  outline: none !important;
  background-color: #242424 !important;
  border: none !important;
  padding: 16px 10px !important;
  border-radius: 6px !important;
  color: #ffffff !important;
  font-size: 16px !important;
  width: 100% !important;
}
.rmsc .dropdown-heading .dropdown-heading-value {
  width: 0px !important;
}
.rmsc .dropdown-heading {
  padding: 0px !important;
  height: 22px !important;
  cursor:  pointer !important;
}
.rmsc .dropdown-container:focus-within {
  box-shadow: unset !important;
}
  /* End the multi dropdown section */
  .accordion-button:not(.collapsed) {
    padding: 16px !important;
    color: #fff !important;
    background-color: #242424 !important;
    box-shadow: unset !important;
}
.accordion-button {
  padding: 16px !important;
  color: #ffffff !important;
  background-color: #242424 !important;
  border-radius: 4px !important;
  border: 1px solid #242424 !important;
}
.accordion-item {
  color: #fff !important;
  background-color: #242424 !important;
  border: 1px solid #242424 !important;
}
.accordion-button::after {
display: none !important;
}
.accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg) !important;
}
.accordion-button::after {
  transition: rotate(-180deg) !important;
}
.accordion-button {
  justify-content: space-between !important;
}
.accordion-button:focus {
  box-shadow: unset !important;
}
.logo{
  width: 24px !important;
  cursor: pointer !important;
}

/* Designed the clock range wise calendar */
.css-10o2lyd-MuiStack-root {
  padding-top: 0px !important;
}
.css-113d811-MuiFormLabel-root-MuiInputLabel-root {
  color: #242424 !important;
  font-size: 0rem !important;
  line-height: 0px !important;
  letter-spacing: 0px !important;
}
.css-1b1fjlj-MuiFormControl-root-MuiTextField-root {
background-color: #242424 !important;
font-size: 16px !important;
border-radius: 6px !important;
outline: none !important;
color: #ffffff !important;
width: 100% !important;
}
.css-19qnlrw-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}
.css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input {
  color: #ffffff !important;
  outline: none !important;
}
.css-jupps9-MuiInputBase-root-MuiOutlinedInput-root {
  color:#ffffff !important;
  border-radius: 0px !important;
  outline: none !important;
  border: none !important;
  outline: none !important;
}
.css-113d811-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #242424 !important ;
}
.rmsc .dropdown-content .panel-content {
  overflow: hidden;
  border-radius: 4px !important;
 background-color: #242424 !important;
 box-shadow: rgba(0, 255, 195, 0.02) 0px 1px 3px 0px, rgba(0, 255, 195, 0.15) 0px 0px 0px 1px !important;


}
.rmsc .select-item:hover {
  background-color: #242424 !important;
  color: #ffffff !important;
}
.rmsc .select-item.selected {
 background-color: #242424 !important;
 color: #ffffff !important;
}
.rmsc .search input:focus {
 background-color: #242424 !important;
}
.rmsc .dropdown-content {
  right: 0px !important;
}
/* Designed the select component */
.css-t3ipsp-control:hover {
  border-color: #242424 !important;
}
.css-t3ipsp-control {
  padding: 10px !important;
  height: 0px !important;
  outline: 0 !important;
  background-color: #242424 !important;
  border-color: none !important;
  outline: none !important;
  border-radius: 6px !important;
}

.css-13cymwt-control:hover {
  border-color: none !important;
}
.css-13cymwt-control {
  min-height: 0px !important;
  background-color: #242424 !important;
  border: none !important;
  border-color: none !important;
  outline: none !important;
  padding: 10px !important;
  border-radius: 6px !important;
  border-width: 0px !important;
  box-sizing: border-box;
}
.css-1nmdiq5-menu {
  background-color: #242424 !important;
  color: #ffffff !important;
  border-radius: 4px;
  box-shadow: unset !important;
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}
.css-10wo9uf-option:active {
  background-color: #242424 !important;
}
.css-d7l1ni-option {
  background-color: #242424 !important;
}
.css-19bb58m {
  color: #ffffff !important;
  visibility: visible;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1 / 1 / 2 / 3;
  grid-template-columns: 0 min-content;
  margin: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.css-hlgwow {
padding: 0px !important;
}
.css-t3ipsp-control {
  min-height: 54px !important;
  padding: 10px !important;
  outline: none !important;
  background-color: #242424 !important;
  border-color: none !important;
  border-radius: 4px;
  border-width: 0px !important;
  box-shadow: unset !important;
}
.css-1dimb5e-singleValue {
  color: #ffffff !important;
}
.css-tr4s17-option {
  background-color:  #00ffc3 !important;
  color:#000000 !important;
}
/* start the time slot conatiner */
.css-oeq6yz-MuiButtonBase-root-MuiIconButton-root {
  background-color: #000000 !important;
  font-size: 1.2rem !important;
  color: #ffffff !important;
  margin-right: 0px !important;
}
.css-1ll44ll-MuiOutlinedInput-notchedOutline {
 outline: none !important;
 border: none !important;
}
.css-qr46ko {
  border-radius: 8px !important;
  box-shadow: rgba(0, 255, 195, 0.02) 0px 1px 3px 0px, rgba(0, 255, 195, 0.15) 0px 0px 0px 1px !important;
}

/* Style the invoice table  */
table{
  width: 100% !important;
} 

/* react Select Date deisgned */
.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker-popper {
  transform: translate(0px, 70px) !important;
}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc !important;
}
.react-datepicker__day {
  color: inherit!important;
}
.react-datepicker__day-name {
  color: #ffffff !important;
}
.react-datepicker__header {
  text-align: center;
  background-color: #000000 !important;
  color: #fff !important;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #fff !important;
}
/* .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #ffffff !important;
} */
.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #000000 !important;
  color: #ffffff !important;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  line-height: initial;
}
.react-datepicker__day:not([aria-disabled=true]):hover, .react-datepicker__month-text:not([aria-disabled=true]):hover, .react-datepicker__quarter-text:not([aria-disabled=true]):hover, .react-datepicker__year-text:not([aria-disabled=true]):hover {
  background-color: #242424 !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: #242424 !important;
  color:#fff !important;
}
/* style the error container */
.errorContainer{
  padding: 20px;
  background-color: #000;
  color: #00ffc3;
  margin: 20px;
  border-radius: 20px;
  font-size: 18px;
}
/* end the error container */
/* Style the main container on entire website */
.main-container{
  background-color: #242424;
  height: 94vh;
  overflow: scroll;
  padding: 20px 0px; 
}
.main-container::-webkit-scrollbar{
  display: none;
}
/* End the main container on entire website */
/* Style the error message in form section */
.error{
  font-size: 12px;
    display: flex;
    align-items: start;
    justify-content: start;
    text-align: start;
    color: red !important;
    width: 100% !important;
    margin-bottom: 0px !important;
}
/* End the error message in form section */
/* Style the all booking details button */
.editButtonSection{
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.viewButton{
  width: 24px;
  cursor: pointer;
}
/* end the all booking details button */
/* Style the Assign Modal Css */
.modalHeading{
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
}
.modal-textarea{
  background-color: #242424;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  outline: none;
  padding: 16px 10px;
  width: 100%;
}
/* End the assign modal css */
/* Start the react repsonsive modal */
.react-responsive-modal-closeButton {
  position: absolute;
  top: 4px !important;
  right: 4px !important;
  width: 16px !important;
  height: 16px !important;
  border: none;
  padding: 0px;
  cursor: pointer;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
.react-responsive-modal-closeButton svg {
  fill: white !important;
}
.react-responsive-modal-modal {

  text-align: center !important;
  background: #000000 !important;
  border-radius: 10px !important;
  border: 1px solid #00ffc3 !important;
}
/* End the react resposnsive modal */
/* Style the table section */
.listAction{
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
}
.listAction img{
  width: 24px;
}
.linkSection{
  font-weight: 600;
  color:  #00ffc3 !important;
  font-size: 16px;
}
/* End the table Section */
/* Style the details container */
.Details-container-section{
  padding: 20px;
}
/* End the details Container */
