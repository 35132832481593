.LoginMainContainer{
    background-color: #242424;
    color: #ffffff;
    height:100vh;
    width: 100%;
    overflow: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LoginMainContainer::-webkit-scrollbar{
    display: none;
}
.formMainContainer{
    width: 100%;
    max-width: 800px;
    margin: auto;
}
.formPassword{
    font-size: 14px;
    color: #ffffff;
    margin-top: -6px;
}
.formSection{
    padding: 30px;
    border-radius: 14px;
    border: 1px solid #00ffc3;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
}
.formImgSection{
    margin-top: 10px;
}
.formContainer{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 600px;
}
.formFiled{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.formLabel{
    font-size: 18px;
    color: #ffffff;
}
.passwordContainer {
    position: relative;
    display: flex;
    align-items: center;
}

.eyeIcon {
    position: absolute;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.formInput{
    outline: none;
    background-color: #242424;
    border: none;
    padding: 16px 10px;
    border-radius: 6px;
    color: #ffffff;
    font-size: 16px;
    width: 100%;
}
.formButtonSection{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    cursor: pointer;
}
.formButton{
    background: #00ffc3 !important;
    color: #000 !important;
    padding: 10px 30px;
    border-radius: 10px;
    font-weight: 600;
}
