.bookingStatusContainer {
    /* border: 2px solid #00ffc3; */
    padding: 18px 16px;
    border-radius: 14px;
    background-color: #000000;
    color: #ffffff;
}

.header {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
}

.details {
    display: flex;
    flex-direction: column;
}

.detailItem {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.label {
 
    font-size: 14px;
    color: #ffffff;
}

.value {
  font-weight: 600;
    color:  #00ffc3;
    font-size: 16px;
}
/* start the accordion section */
.accordionContainer {
    /* border: 2px solid #00ffc3; */
    padding: 18px 16px;
    border-radius: 14px;
    background-color: #000000;
    color: #ffffff;
    width: 100%;
    height: auto;
    /* overflow: scroll; */
  }
  .accodionPTag{
    margin-bottom: 0px !important;
  }
  .accordionContainer::-webkit-scrollbar{
    display: none;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .status {
    color:  #00ffc3;
    font-size: 22px;
    font-weight: bold;
  }
  
  .confirmation {
    text-align: right;
  }
  
  .confirmation p {
    margin: 0;
  }
  
  .date {
    font-size: 0.9rem;
    color: #888;
  }
  
  .accordion {
    margin-top: 15px;
  }
  
  .accordionItem {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 8px;
    overflow: hidden;
  }

  .accordionHeader {
    background-color: #242424 !important;
    color: #fff;
  }
  
  .accordionBody {
    padding: 15px 20px;
    color: #ffffff !important;
    background-color: #000000 !important;
  }
.customRow{
  border-bottom: 1px solid #ffffff;
}

.bookingDescriptionContainer{
  padding: 18px 16px;
  border-radius: 14px;
  background-color: #000000;
  color: #ffffff;
  margin-top: 40px;
}
/* start the Booking details pod section */
.iconContainer{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
}
.iconButton{
  border: 1px solid #00ffc3;
  background-color: #000000;
  color: #fff;
  padding: 12px 16px;
  border-radius: 8px;
}
.iconButton.active {
background-color: #00ffc3;
color: #000000;
}

.iconButton:hover{
background-color: #00ffc3;
color: #000000;
}
.iconButton span{
  font-size: 15px;
  font-weight: 600;
}