.dashboard {
  background-color: #242424;
  height: 94.2vh;
  overflow: scroll;
}
.dashboard::-webkit-scrollbar {
  display: none;
}
.row {
  padding: 20px;
}
.mapContainer{
  border: 2px solid red;
  height: 100px !important;
}

.mapResponsive div {
  border-radius: 20px !important;
}