.container {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #fff;
  color: #636b6f;
  font-weight: 400;
  height: 100vh;
  background-color: #242424;
}

.code {
  font-size: 26px;
  padding: 0 6px;
  color: #00ffc3;
}

.seperator {
  font-size: 30px;
  padding: 0 6px;
  color: #00ffc3;
}

.message {
  font-size: 18px;
  color: #00ffc3;
}
