/* Style the main container section */
.ridersContainer{
  background-color: #242424;
  height: 94.3vh;
  overflow: scroll;
  padding: 20px;
  color: #ffffff;
}
.ridersContainer::-webkit-scrollbar{
  display: none;
  
}
/* end the main container section */
.addShopContainer {
  background-color: #242424;
  height: 94vh;
  overflow: scroll;
  padding: 20px;
  color: #ffffff;
}
.addShopContainer::-webkit-scrollbar{
  display: none;
}
.addShopFormSection{
  padding: 30px;
  border-radius: 14px;
  border: 1px solid #00ffc3;
  background-color: #000000;
  display: flex;
  flex-direction: column;
}
.addHeading{
  color: #00ffc3;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
.addShopInputContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.formSection {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.addShopLabel{
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
}
.row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.inputField {
  outline: none;
  background-color: #242424;
  border: none;
  padding: 16px 10px;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  width: 100%;
}
.editButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  cursor: pointer;
  margin-top: 20px;
}

/* .editCancelBtn {
  background-color: #ffffff;
  padding: 10px 26px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
} */

.editSubmitBtn {
  color: #000000;
  border: 1px solid #00ffc3 !important;
  background-color: #00ffc3;
  padding: 10px 26px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 600;
}
.editSubmitBtn:hover {
  color: #ffffff;
  background-color: #000000;
}
/* Style the image section container */
 /* Start the file upload section code */
 .fileUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  /* width: 50%; */
}

.fileLabel {
  width: 100%;
  font-size: 18px;
  margin-bottom: 10px;
  color: #ffffff; 
}

.fileDropZone {
  position: relative;
  width: 100%;
  padding: 40px;
  border-radius: 14px;
background-color: #242424;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.fileUploadLabel {
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  column-gap: 22px;
  cursor: pointer;
}

.uploadIcon {
  width: 60px;
  height: 60px;
}

.imageContainer {
position: relative;
overflow: hidden;
border-radius: 8px;
}

.previewImage {
width: 100%;
height: 200px;
  object-fit: cover;
  top: 50%;
  transform: translate(0, 0%);
  position: relative;
  max-width: 100%;
  max-height: 100%;
  background-color: #FFF;
  transition: border-color .15s linear;
}

.removeButton {
  position: absolute;
  background-color:#00ffc3;
  color: #000000 !important;
  padding: 0px;
  top: 5px;
  right: 5px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 4px;
}
/* End the file upload section */
/* End the style the image section */
.errorContainer{
  padding: 20px;
  background-color: #000;
  color: #00ffc3;
  margin: 20px;
  border-radius: 20px;
  font-size: 18px;
}

/* Start the media query */
@media only screen and (max-width: 991px) {
  .row {
    flex-direction: column;
      }
}
