.headerContainer {
  background-color: #242424;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 14px 30px;
  gap: 18px;
  font-family: "Inter", sans-serif !important;
}

.notificationContainer img {
  width: 22px;
  height: auto;
  cursor: pointer;
}

.profileContainer img {
  width: 30px;
  height: auto;
  cursor: pointer;
  border-radius: 50%;
}
.notificationhead {
  padding: 0px !important;
}
.notificationContainer,
.profileContainer {
  position: relative;
  cursor: pointer;
}

.notificationDropdown {
  position: absolute;
  top: 32px;
  right: 0;
  color: #ffffff;
  background-color: #000000;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-size: 14px;
}

.notificationBox {
  width: 50px;
  height: 40px;
  border-radius: 50%;
  background-color: #00ffc3;
  box-shadow: #00ffc3 0px 2px 14px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
}

.notificationSection {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  height: 340px;
  overflow: scroll;
  padding: 16px 14px;
}

.notificationSection::-webkit-scrollbar {
  display: none;
}
.notificationContentsection {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.notificationTitle {
  font-size: 14px;
  color: #00ffc3;
}
.notificationText {
  width: 100%;
  font-size: 12px;
  color: #ffffff;
}
.notificationDate {
  width: 80px;
  text-align: center;
}
.notificationTime {
  color: #ffffff;
  font-size: 11px;
}
.ImgContainer {
  border: 1px solid #ffffff;
  width: 26px;
  height: 26px;
  padding: 4px;
  border-radius: 4px;
}

.notificationBottomSection {
  border-top: 1px solid #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;
}

.notificationCount {
  font-size: 14px;
}

.notificationAllList {
  font-size: 14px;
  color: #ffffff !important;
  cursor: pointer;
  text-decoration: none !important;
}
a {
  text-decoration: none !important;
}

.notificationAllList:hover {
  color: #00ffc3;
}

.notificationContent {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.profileDropdown {
  position: absolute;
  top: 34px;
  right: 0;
  background: #000000;
  width: 160px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: 6px;
  color: #ffffff;
  padding: 12px 20px;
}

.profileDropdownOption {
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 8px;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid white;
}
.profileDropdownsOption {
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 8px;
  padding: 10px 0px 0px 0px;
}
.profileDropdownOption:hover {
  color: #00ffc3;
}
.profileDropdownOption:hover .ImgContainer {
  border-color: #00ffc3;
}
.profileDropdownsOption:hover {
  color: #00ffc3;
}
.profileDropdownsOption:hover .ImgContainer {
  border-color: #00ffc3;
}
.notificationDropdown p,
.profileDropdown p {
  margin: 0;
  padding: 4px 0;
  cursor: pointer;
}
.profileDropdownOptionSelected {
  color: #00ffc3;
}
.activeImgBorder {
  border: 1px solid #00ffc3;
}
