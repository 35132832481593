.card {
  border: 2px solid #00ffc3;
  color: #fff;
  border-radius: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.card:hover {
  background-color: #00ffc3;
  color: #000000 !important;
  cursor: pointer;
}

.card:hover .cardIcon {
  filter: brightness(0) saturate(100%);
}

.cardWrapper {
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin-bottom: 5px;
}

.cardIcon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardCount {
  font-size: 30px;
}

.cardTitle {
  font-size: 18px;
  line-height: 20px;
}

.cardActive {
  background-color: #00ffc3;
  color: #000000 !important;
}

.cardActive .cardIcon {
  filter: brightness(0) saturate(100%);
}

@media (max-width: 1400px) {
  .card {
    padding: 20px;
    row-gap: 0px;
  }
  .cardIcon {
    width: 40px;
    height: 35px;
  }
  .cardTitle {
    font-size: 16px;
  }
}
