.subHeaderContainer {
    padding: 20px;
    background-color: #242424;
    color: white;
    font-family: "Inter", sans-serif !important;
}

.headerCharger {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subHeaderButtonSection {
    display: flex;
    column-gap: 14px;
}

.headingList {
    font-size: 24px;
    font-weight: 600;
    color: #00ffcc;
}
.headCardSection{
    background-color: #000000;
    border: 1px solid #00ffcc;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    column-gap: 20px;
}
.headCardNumber{
    border: 1px solid #00ffcc;
    color: #00ffcc;
    font-size: 18px;
    font-weight: 600;
    min-width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 5px;
}
.headCardText{
    font-size: 18px;
    color: #fff;
    font-weight: 550;

}
.addButtonSection {
    display: flex;
    align-items: center;
    column-gap: 8px;
    background-color: #00ffcc;
    border: 1px solid #00ffcc !important;
    color: #000000;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.addButtonSection:hover {
    background-color: #000000;
    /* border: 1px solid #00ffcc; */
    color: #fff;
}

.addButtonSection:hover .addButtonImg img {
    filter: brightness(0) invert(1);
}

.addButtonImg img {
    width: 24px;
    height: auto;
}

.addButtonText {
    font-size: 16px;
    font-weight: 600;
}