.electricBikeSection{
    background-color: #242424;
    height: 94.3vh;
    overflow: scroll;
    padding: 20px 0px;
}
.electricBikeSection::-webkit-scrollbar{
    display: none;
}
.stationContainer{
    background-color: #242424;
    height: 94.2vh;
    overflow: scroll;
    padding: 20px 0px;
}
.stationContainer::-webkit-scrollbar{
    display: none;
}
.appSignupSection{
    background-color: #242424;
    height: 94.2vh;
    overflow: scroll;
    padding: 20px 0px;
}
.appSignupSection::-webkit-scrollbar{
    display: none;
}
.ChargerDetailsSection{
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}