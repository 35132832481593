.addShopContainer {
  background-color: #242424;
  height: 94vh;
  overflow: scroll;
  padding: 20px;
  color: #ffffff;
}

.addShopContainer::-webkit-scrollbar {
  display: none;
}
.mainShopContainer{
  display: flex;
    flex-direction: column;
    row-gap: 20px;
    justify-content: start;
    position: relative;
    top: -32px;
}
.mainAddSection{
  width: 100%;
  display: flex;
  justify-content: end;
  column-gap: 10px;
}
.shoplistContainer {
  background-color: #242424;
  height: 94.2vh;
  overflow: scroll;
  padding: 20px;
  color: #ffffff;
}

.addButtons {
  color: #000000;
  background-color: #00ffc3;
  padding: 10px 26px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 600;
}

.shoplistContainer::-webkit-scrollbar {
  display: none;
}

.textarea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.mapMainContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.mapEmbedContainer {
  width: 100%;
  margin-top: 10px;
  display: flex;
  gap: 20px;
  justify-content: start;
}

.mapContainer {
  width: 400px;
  position: relative;
  height: auto;
  border-radius: 10px;
}

.mapContainer img {
  width: 350px;
  height: auto;
  border-radius: 6px;
}

.addShopFormSection {
  padding: 30px;
  border-radius: 14px;
  border: 1px solid #00ffc3;
  background-color: #000000;
  display: flex;
  flex-direction: column;
}

.closeButton {
  position: absolute;
  display: flex;
  top: 10px;
  right: 10px;
  background: #00ffc3;
  border: none;
  color: #000000;
  width: 20px;
  height: 20px;
  font-size: 14px;
  cursor: pointer;
  padding: 0px;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
  justify-content: center;
  align-items: center;
}

.addHeading {
  color: #00ffc3;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.addShopInputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.formSection {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.addShopLabel {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
}

.row {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.inputField {
  outline: none;
  background-color: #242424;
  border: none;
  padding: 16px 10px;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  width: 100%;
}

.inputFieldFull {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.locationRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
}

.selectWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.selectField {
  outline: none;
  background-color: #242424;
  border: none;
  padding: 16px 10px;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  width: 100%;
  appearance: none;
  cursor: pointer;
}

.dropdownIcon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 10px;
  pointer-events: none;
  transform: translateY(-50%);
  font-size: 16px;
  color: #ffffff;
}

.addButton {
  border: 1px solid #00ffc3 !important;
  background-color: #00ffc3;
  font-weight: 600;
  display: flex;
  color: #000000;
  padding: 6px 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.addButton:hover {
  background-color: #000000;
  color: #ffffff;
}
.addButton:hover .imageShopList {
  filter: brightness(0) invert(1);
}
.addButton span {
  font-size: 18px;
  font-weight: 600;
}

.scheduleSection {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
}

.alwaysOpen {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.alwaysOpen label {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  cursor: pointer;
}

.dayRow {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px;
}

.dayLabel {
  width: 220px;
}

.timeField {
  outline: none;
  width: 90%;
  background-color: #242424;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: #ffffff !important;
}

.textAreaField {
  outline: none;
  background-color: #242424;
  border: none;
  padding: 16px 10px;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  width: 100%;
}

.addShopAddressSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 6px;
}

.addShopAddress {
  display: flex;
  column-gap: 20px;
  row-gap: 6px;
  width: 100%;
}

.timeSlotContainer {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}

.classTimeslot {
  width: 100px;
}

.inputLabel {
  width: 100%;
  display: flex;
  gap: 20px;
}
.openSection{
  width: 100px;
}
/* Style the file uplaod section */
.fileUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  /* width: 50%; */
}

.fileLabel {
  width: 100%;
  font-size: 18px;
  margin-bottom: 10px;
  color: #ffffff;
}

.fileDropZone {
  position: relative;
  width: 100%;
  padding: 40px;
  border-radius: 14px;
  background-color: #242424;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.fileUploadLabel {
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  column-gap: 22px;
  cursor: pointer;
}

.uploadIcon {
  width: 60px;
  height: 60px;
}

.imageContainer {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.previewImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
  /* top: 50%; */
  transform: translate(0, 0%);
  position: relative;
  max-width: 100%;
  max-height: 100%;
  background-color: #FFF;
  transition: border-color .15s linear;
}

.removeButton {
  position: absolute;
  background-color: #00ffc3;
  color: #000000 !important;
  padding: 0px;
  top: 5px;
  right: 5px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 4px;
}


.galleryContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0px;
  width: 100%;
}
.galleryContainer::-webkit-scrollbar {
  width: 6px;
}

.galleryContainer::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 3px;
}


.editButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  cursor: pointer;
  margin-top: 20px;
}

.editCancelBtn {
  background-color: #ffffff;
  padding: 10px 26px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.editSubmitBtn {
  border: 1px solid #00ffc3;
  color: #000000;
  background-color: #00ffc3;
  padding: 10px 26px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 600;
}

.editCancelBtn:hover,
.editSubmitBtn:hover {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #00ffc3;
}

/* Container for toggle switch */
/* Container for toggle switch */
.toggleContainer {
  width: 220px;
  display: flex;
  gap: 20px;
  padding: 18px 10px;
  border-radius: 14px;
  background-color: #242424;
  border: 1px solid #00ffc3;
}

/* Label for status */
.statusLabel {
  color: #ffffff;
  font-size: 18px;
}

/* Toggle switch area */
.toggleSwitch {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  /* Space between the slider and text */
}

/* Toggle button area */
.toggleButton {
  position: relative;
  border: 2px solid;
  border-radius: 6px;
  width: 60px;
  height: 34px;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.toggleButton.activeToggle {
  border-color: #00ffc3;
}

.toggleButton.inactiveToggle {
  border-color: #ffffff;
}

.slider {
  position: absolute;
  top: 50%;
  left: 0px;
  width: 28px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 0px;
  transform: translateY(-50%);
  transition: left 0.3s ease, background-color 0.3s ease;
}

.toggleButton.activeToggle .slider {
  left: 28px;
  background-color: #00ffc3;
}

.toggleButton.inactiveToggle .slider {
  background-color: #ffffff;
}

.toggleText {
  font-size: 14px;
  transition: color 0.3s ease;
}

.toggleText.activeText {
  color: #00ffc3;
}

.toggleText.inactiveText {
  color: #ffffff;
  width: 60px;
}

.error {
  font-size: 12px;
  display: flex;
  align-items: start;
  justify-content: start;
  text-align: start;
  color: red !important;
  width: 100% !important;
  margin-bottom: 0px !important;
}

/* End the feature section */

/* Start the checkmark design */
.checkboxInput {
  margin-right: 10px;
}

.arrow {
  font-size: 12px;
  font-weight: 800;
}

.checkboxInput {
  display: none;
}

.checkmark {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #00ffc3;
  border-radius: 2px;
  cursor: pointer;
  background-color: #000000;
  transition: background-color 0.3s, border-color 0.3s;
}

/* Style when checkbox is checked */
.checkboxInput:checked+.checkmark {
  background-color: #00ffc3;
  border-color: #00ffc3;
}

/* Checkmark */
.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxInput:checked+.checkmark::after {
  display: block;
}
/* Style the bremove button in form list */
.formRemoveButton {
  background-color: #000000;
  border: 1px solid #00ffc3 !important;
  background-color: #ffffff;
  font-weight: 600;
  display: flex;
  color: #000000;
  padding: 6px 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}
.formRemoveButton:hover {
  background-color: #000000;
  color: #ffffff;
}
.formRemoveButton:hover .imageShopList {
  filter: brightness(0) invert(1);
}
.imageShopList{
  width:20px
}
.addSpan{
  font-size: 16px;
}