.containerCharger{
    background-color: #242424;
    height: 94vh;
    overflow: scroll;
    padding: 20px;
    color: #ffffff;
  }
  .containerCharger::-webkit-scrollbar{
    display: none;
  }
  .title {
    color: #00ffc3;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .chargerSection{
    padding: 30px;
    border-radius: 14px;
    border: 1px solid #00ffc3;
    background-color: #000000;
    display: flex;
    flex-direction: column;
  }
  
  .form {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
  }
  
  .row {
    display: flex;
    column-gap: 20px;
    margin-bottom: 10px;
  }
  
  .inputGroup {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  
  .inputGroup:last-child {
    margin-right: 0;
  }
  
  .label {
    font-size: 18px;
    color: #ffffff;
    font-weight: 500;
  }
  
.inputCharger{
    outline: none;
    background-color: #242424;
    border: none;
    padding: 16px 10px;
    border-radius: 6px;
    color: #ffffff;
    font-size: 16px;
    width: 100%;
}

  .select {
    appearance: none;
    outline: none;
    background-color: #242424;
    border: none;
    padding: 16px 10px;
    border-radius: 6px;
    color: #ffffff;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
  .iconContainer {
    position: fixed;
    right: 62px;
    top: 22%;
    font-size: 16px;
    color: #ffffff;
    z-index: 2;
}
  .select option{
    cursor:  pointer !important;
  }
  .actions {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    margin-top: 20px;
  }
  /* style the error section */
  .errorContainer{
    padding: 20px;
    background-color: #000;
    color: #00ffc3;
    margin: 20px;
    border-radius: 20px;
    font-size: 18px;
  }
  /* end te error section */
  
  .cancelBtn {
    background-color: #ffffff;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    outline: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .submitBtn {
    border: 1px solid #00ffc3 !important;
    background-color:#00ffc3;
    color: #000000;
    outline: none;
    border: none;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .submitBtn:hover {
    color: #ffffff;
    background-color: #000000;
  }
  
  .cancelBtn:hover {
    border: 1px solid #00ffc3 !important;
    background-color: #000000;
    color: #ffffff;
  }
  /* Start the file upload section code */
  .fileUpload {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    /* width: 50%; */
}

.fileLabel {
    width: 100%;
    font-size: 18px;
    margin-bottom: 10px;
    color: #ffffff; 
}

.fileDropZone {
    position: relative;
    width: 100%;
    padding: 40px;
    border-radius: 14px;
background-color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.fileUploadLabel {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    column-gap: 22px;
    cursor: pointer;
}

.uploadIcon {
    width: 60px;
    height: 60px;
}

.imageContainer {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.previewImage {
  width: 100%;
  height: 200px;
    object-fit: cover;
    top: 50%;
    transform: translate(0, 0%);
    position: relative;
    max-width: 100%;
    max-height: 100%;
    background-color: #FFF;
    transition: border-color .15s linear;
}

.removeButton {
    position: absolute;
    background-color:#00ffc3;
    color: #000000 !important;
    padding: 0px;
    top: 5px;
    right: 5px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 4px;
}
/* End the file upload section */
/* Container for toggle switch */
/* Container for the toggle */
.toggleContainer {
  width: 220px;
  display: flex; 
  gap: 20px;
  padding: 18px 10px;
  border-radius: 14px;
  background-color: #242424;
  border: 1px solid #00ffc3;
}

/* Label for status */
.statusLabel {
  color: #ffffff;
  font-size: 18px;
}

/* Toggle switch area */
.toggleSwitch {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px; /* Space between the slider and text */
}

/* Toggle button area */
.toggleButton {
  position: relative;
  border: 2px solid;
  border-radius: 6px;
  width: 60px;
  height: 34px;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}
.toggleButton.activeToggle {
  border-color: #00ffc3;
}

.toggleButton.inactiveToggle {
  border-color: #ffffff;
}

.slider {
  position: absolute;
  top: 50%;
  left: 0px; 
  width: 28px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 0px;
  transform: translateY(-50%);
  transition: left 0.3s ease, background-color 0.3s ease; 
}

.toggleButton.activeToggle .slider {
  left: 28px;
  background-color: #00ffc3;
}

.toggleButton.inactiveToggle .slider {
  background-color: #ffffff; 
}

.toggleText {
  font-size: 14px;
  transition: color 0.3s ease;
}

.toggleText.activeText {
  color: #00ffc3; 
}

.toggleText.inactiveText {
  color: #ffffff; 
  width: 60px;
}


/* End the feature section */