.accordionContainer {
    margin-top: 10px !important;
    background-color: #000000 !important;
    border:none;
    border-radius: 4px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}
.accordionCard {
    background-color: #000000 !important;
    border: none !important;
}
.accordionBody {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.10s ease;
}

.open .accordionBody {
    max-height: 1000px !important;
}
.filterForm {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    color: #ffffff;
    row-gap: 20px;
}

.filterItem {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 8px;
    padding: 0px 10px;
}
.filterLabel{
font-size: 16px;
}
.filterInput{
    background-color: #000000;
    border: none;
    outline: none;
    border-bottom: 1px solid #ffffff;
    padding: 6px 10px;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
}
.filterSelect{
    background-color: #000000;
    border: none;
    outline: none;
    border-bottom: 1px solid #ffffff;
    padding: 6px 10px;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
}
@keyframes slideDown {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 200px; 
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        max-height: 200px;
        opacity: 1;
    }
    to {
        max-height: 0;
        opacity: 0;
    }
}

.slideDown {
    animation: slideDown 0.3s ease forwards;
}

.slideUp {
    animation: slideUp 0.3s ease forwards;
}

.customSelectWrapper {
    position: relative;       
    width: 100%;                 
}

.customSelect {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 35px;
    width: 100%;
    position: relative;
}

.customSelectWrapper {
    position: relative;
}

.customSelectIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 18px;
    color: white;
    transition: transform 0.3s ease;
}
.customSelectWrapper.open .customSelectIcon {
    transform: translateY(-50%) rotate(180deg);
}